<div class="preview">
    <ng-container *ngIf="jpgChecked || pngChecked">
        <div class="preview-img">
            <img [src]="imageBase64" (error)="imgError($event)" />
        </div>
    </ng-container>
    <ng-container *ngIf="svgChecked">
        <div class="preview-svg" [innerHTML]="svg | nzSanitizer: 'html'"></div>
    </ng-container>
</div>
