export const CONSTANTS = {
  dialogWidth: 1320,
  dialogWidthGap: 40,
  gridMaxHeight: 400,
  help: {
    confluenceOnline: 'https://docs.cuesystem.com/space/TAM/162201668/Introduction',
    topicUrl: 'topic/',
    tabName: 'touchONE-admin | Administration Manual',
  },
  paths: {
    security: 'security',
    calendar: {
      logs: 'calendar/logs',
      main: 'calendar',
      configuration: 'calendar/configuration',
      users: 'calendar/users',
      userGroups: 'calendar/user-groups',
      resources: 'calendar/resources',
      identityProviders: 'calendar/identity-providers',
      timetables: 'calendar/timetables',
    },
    navigation: 'navigation',
    news: 'news',
    notFound: 'notFound',
    system: 'system',
    setup: 'setup',
    firmware: 'firmware',
    backupRestore: 'backupRestore',
    servers: 'servers',
    serverExchangeAndAD: 'serverExchangeAndAD',
    serverOffice365: 'serverOffice365',
    serviceAccounts: 'serviceAccounts',
    connectionAgents: 'connectionAgents',
    resources: 'resources',
    administration: 'administration',
    units: 'units',
    properties: 'properties',
    areas: 'areas',
    newAreas: 'newAreas',
    languages: 'languages',
    support: 'support',
    identification: 'identification',
    settings: 'settings',
    users: 'users',
    timeSettings: 'timeSettings',
    timetables: 'timetables',
    analytics: 'analytics',
    subjects: 'subjects',
    activeDirectories: 'activeDirectories',
    emailAccount: 'emailAccount',
    account: 'account',
    accountMe: 'me',
    accountExist: 'exist',
    accountRegister: 'register',
    accountLogin: 'login',
    oauthCallback: 'oauth-callback',
    accountOauthLogin: 'admin-oauth-login',
    accountLogout: 'logout',
    accountForgottenPassword: 'forgottenPassword',
    accountResetPassword: 'resetPassword',
    about: 'about',
    sensor: 'sensor',
    analyticsBasicOverview: 'analyticsBasicOverview',
    analyticsTemplate: 'analyticsTemplate',
    files: 'files',
    log: 'log',
    resourceProperties: 'resourceProperties',
    licenses: 'licenses',
    resourceTypes: 'resourceTypes',
    touchONEAssist: 'assist',
    uploadCertificate: 'uploadCertificate',
    adminUsers: 'adminUsers',
    activateLicenseKey: 'activateLicenseKey',
    dashboard: 'dashboard',
    notifications: 'notifications',
    qrCodes: 'qrCodes',
    qrCategories: 'qrCategories',
    integrations: 'integrations',
    webex: 'integrations/webex',
    accessDenied: 'accessDenied',
    userGroups: 'userGroups',
    debug: 'debug',
    forgottenPIN: 'forgottenPIN',
    setPIN: 'setPIN',
    newAnalytics: 'newAnalytics',
    imageTemplates: 'imageTemplates',
    kiosks: 'kiosks',
    cookies: 'cookies',
    design: 'design',
    digitalSignage: 'digitalSignage',
    paths: 'paths',
    googleAnalytics: 'integrations/google-analytics',
    projects: 'projects',
  },
  api: {
    patchNavigation: '/area/patchNavigation',
    prefix: '/api',
    grid: '/grid',
    loadData: '/loadData',
    loadForDetail: '/loadForDetail',
    load: '/load',
    add: '/add',
    copy: '/copy',
    edit: '/edit',
    multiEdit: '/multiEdit',
    delete: '/delete',
    import: '/import',
    importAD: '/importAD',
    refresh: '/refresh',
    refreshAD: '/refreshAD',
    sendPin: '/sendPin',
    info: '/info',
    unregister: '/unregister',
    test: '/test',
    sendEmail: '/sendEmail',
    generateToken: '/generateToken',
    download: '/download',
    treelist: '/treelist',
    floorplan: '/floorplan',
    multiTest: '/multiTest',
    remoteSync: '/remotesync',
    dashboardData: '/dashboardData',
  },
  entity: {
    qrCategory: '/qrCategory',
    news: '/news',
    server: '/server',
    serviceAccount: '/serviceAccount',
    resource: '/resource',
    area: '/area',
    timeSetting: '/timeSetting',
    subject: '/subject',
    unit: '/unit',
    systemConfiguration: '/systemConfiguration',
    serverType: '/serverType',
    unconfirmedEventAction: '/unconfirmedEventAction',
    abandonedResourceAction: '/abandonedResourceAction',
    timeZone: '/timeZone',
    maximumAdvanceReservation: '/maximumAdvanceReservation',
    occupancyIndication: '/occupancyIndication',
    organizerReplacementType: '/organizerReplacementType',
    language: '/language',
    activeLanguage: '/activeLanguage',
    timeTable: '/timeTable',
    activeDirectory: '/activeDirectory',
    user: '/user',
    pin: '/pin',
    userImport: '/userImport',
    userRefresh: '/userRefresh',
    picture: '/picture',
    serviceType: '/serviceType',
    service: '/service',
    serviceTypeUser: '/serviceTypeUser',
    serviceTypeUserImport: '/serviceTypeUserImport',
    resourceImport: '/resourceImport',
    resourceRefresh: '/resourceRefresh',
    resourceAndUser: '/resourceAndUser',
    resourceUserImport: '/resourceUserImport',
    resourceAndService: '/resourceAndService',
    resourceServiceImport: '/resourceServiceImport',
    unitAndResource: '/unitAndResource',
    unitAndServiceType: '/unitAndServiceType',
    productModel: '/productModel',
    productModelType: '/productModelType',
    product: '/product',
    userChangeLanguage: '/userChangeLanguage',
    firstDayOfWeek: '/firstDayOfWeek',
    firstWeekInYear: '/firstWeekInYear',
    dateFormat: '/dateFormat',
    timeFormat: '/timeFormat',
    viewDetailsOfMeeting: '/viewDetailsOfMeeting',
    hidePrivateMeeting: '/hidePrivateMeeting',
    layout: '/layout',
    roomNameFontSize: '/roomNameFontSize',
    account: '/account',
    system: '/system',
    orderResourceBy: '/orderResourceBy',
    displayOrientation: '/displayOrientation',
    unitAndPictureInfoMode: '/unitAndPictureInfoMode',
    serverExchangeAndAD: '/serverExchangeAndAD',
    serverOffice365: '/serverOffice365',
    connectionAgent: '/connectionAgent',
    sensor: '/sensor',
    analyticsTemplate: '/analyticsTemplate',
    analyticsBasicOverview: '/analyticsBasicOverview',
    resourceAndSensor: '/resourceAndSensor',
    file: '/file',
    folder: '/folder',
    log: '/log',
    frameAncestors: '/frame-ancestors',
    logSettings: '/logSettings',
    resourceProperty: '/resourceProperty',
    resourcePropertyItem: '/resourcePropertyItem',
    assist: '/assist',
    resourceType: '/resourceType',
    license: '/license',
    adminUsers: '/adminUsers',
    template: '/template',
    qrCode: '/qrCode',
    userGroup: '/userGroup',
    event: '/event',
  },
  dialog: {
    add: 'add',
    copy: 'copy',
    edit: 'edit',
    delete: 'delete',
    contentEdit: 'contentEdit',
    import: 'import',
    refresh: 'refresh',
    detail: 'detail',
    userImport: 'userImport',
    userEdit: 'userEdit',
    userDelete: 'userDelete',
    serviceImport: 'serviceImport',
    serviceAdd: 'serviceAdd',
    serviceEdit: 'serviceEdit',
    serviceDelete: 'serviceDelete',
    serviceTypeEdit: 'serviceTypeEdit',
    sendPin: 'sendPin',
    unregister: 'unregister',
    multiedit: 'multiedit',
    generateToken: 'generateToken',
    qrCodes: 'qrCodes',
    synchronization: 'synchronization',
  },
  action: {
    edit: 'edit',
    refresh: 'refresh',
    test: 'test',
    delete: 'delete',
    copy: 'copy',
    add: 'add',
    multiEdit: 'multiEdit',
    multiRefresh: 'multiRefresh',
    multiTest: 'multiTest',
    multiDelete: 'multiDelete',
  },
  myError: {
    timeoutError: 'timeoutError',
  },
  code: {
    serverTypeExchange: 2,
    serverTypeOffice365: 1,
    serverTypeSMTP: 3,
    serverTypeGoogle: 4,
    serverTypeCalendar: 5,
    serverTypeOffice365ServerAddress: 'graph.microsoft.com',
    organizerReplacementTypeWithText: 3,
    supportCleaning: 1,
    supportCatering: 2,
    productModelTypeOverview: 2,
    productModelTypePanel: 1,
    sensorTypeContactClosureOccupancySensor: 1,
    displayOrientationLandscape: 0,
    displayOrientationPortrait: 1,
    displayOrientationLandscapeFlipped: 2,
    displayOrientationPortraitFlipped: 3,
    layoutSingleResourceModeSkin1: 11,
    layoutSingleResourceModeSkin2: 12,
    layoutMeetingListSkin1: 13,
    layoutMeetingListSkin2: 14,
    fileTypeFolder: 'folder',
    fileTypeFile: 'file',
    resourceTypeSystemWorkplace: 'workplace',
    resourceTypeSystemRoom: 'room',
    resourceTypeSystemCustom: 'custom',
    dataTypeBoolean: 1,
    dataTypeNumber: 2,
    dataTypeDecimal: 3,
    dataTypeString: 4,
    dataTypeArray: 5,
    dataTypeHTMLContent: 6,
    licenseAdmin: 'CS0604',
    licenseWorkplace: 'CS0601',
    licenseCustom: 'CS0606',
    licensePoiBasic: 'CS0642',
    licensePoiStandard: 'CS0621',
    adminRole: 1,
    analyticsRole: 2,
    confirmByAssist: 1,
    confirmByUnit: 2,
    confirmBySensor: 3,
    conAgntSendingEmails: 1,
    conAgntImportUsers: 2,
    conAgntApiOperations: 3,
    conAgntUnits: 4,
    conAgntTouchONEAssist: 5,
    busyOffice365Id: 3,
    busyExchangeId: 8,
    busyGoogleId: 12,
    busyCaledarId: 14,
    freeOffice365Id: 1,
    freeExchangeId: 6,
    freeGoogleId: 11,
    serviceUnitTypeCleaning: 1,
    serviceUnitTypeCatering: 2,
    serviceUnitTypeService: 3,
    touchOne10wProductModelId: 11,
    licenseKioskBasic: 'CS0637',
    licenseKioskStandard: 'CS0638',
    licenseKioskPremium: 'CS0639',
    licenseResourceBasic: 'CS0640',
    licenseResourceStandard: 'CS0641',
    licenseResourcePremium: 'CS0600',
  },
  defaults: {
    systemConfiguration_minEventDuration: 15,
    systemConfiguration_maxEventDuration: 15,
    systemConfiguration_enableFindFreeResource: false,
    systemConfiguration_selectSubjectFromList: false,
  },
  limits: {
    serviceTypeAndUser_maxUserCountPerOneServiceType: 4,
    resourceAndUser_maxUserCountPerOneResource: 8,
    area: 10,
    subject: 10,
    activeLanguage: 50,
    service_maxServiceCountPerCleaning: 1,
    service_maxServiceCountPerServiceType: 10,
  },
  numerics: {
    resource_abandonedResourceReleaseTime: { format: "'n'", min: 5, max: 90, step: 5 },
    resource_abandonedResourceWarningEmailTime: { format: "'n'", min: 0, max: 75, step: 5 },
    unit_backLightSaverTimeout: { format: "'n'", min: 0, max: 60, step: 5 },
    unit_beepVolume: { format: "'n'", min: 10, max: 100, step: 10 },
    unit_maxLinesToShowPortrait: { format: "'n'", min: 10, max: 25, step: 1 },
    unit_maxLinesToShowPortraitInfoMode: { format: "'n'", min: 10, max: 15, step: 1 },
    unit_maxLinesToShowLandscape: { format: "'n'", min: 7, max: 14, step: 1 },
    unit_lineRefreshTime: { format: "'n'", min: 3, max: 30, step: 5 },
    unit_idleTimeout: { format: "'n'", min: 20, max: 120, step: 10 },
    resource_cancelAllDayMeetingTime: { format: "'n'", min: 0, max: 1440, step: 1 },
    resource_cancelUnconfirmedMeetingTime: { format: "'n'", min: 5, max: 90, step: 5 },
    resource_endWarningTime: { format: "'n'", min: 1, max: 60, step: 5 },
    server_connectTimeOut: { format: "'n'", min: 5, max: 120, step: 5 },
    resource_endReservationWarningTime: { format: "'n'", min: 1, max: 60, step: 1 },
    unit_lcdBackLight: { format: "'n'", min: 0, max: 100, step: 10 },
    systemConfiguration_maskExchangeDropoutTime: { format: "'n'", min: 0, max: 120, step: 5 },
    systemConfiguration_maxEventDuration: { format: "'n'", min: 30, max: 1440, step: 30 },
    systemConfiguration_maxExtendedEventDuration: { format: "'n'", min: 30, max: 1440, step: 30 },
    systemConfiguration_minEventDuration: { format: "'n'", min: 5, max: 60, step: 5 },
    systemConfiguration_occupiedBefore: { format: "'n'", min: 0, max: 20, step: 1 },
    resource_reservationTime: { format: "'n'", min: 10, max: 1000, step: 10 },
    resource_unconfirmedEventWarningEmailTime: { format: "'n'", min: 0, max: 75, step: 5 },
    log_deleteAfterDays: { format: "'n'", min: 5, max: 60, step: 1 },
    assist_defaultAddEndDays: { format: "'n'", min: 0, max: 30, step: 1 },
    areas_maxUtilization: { format: "'n'", min: 0, max: 100, step: 1 },
  },
  timepickers: {
    analyticsBasicOverview_workingHoursDaily: { format: 'HH:mm', steps: { hour: 1, minute: 10 } },
  },
};
