import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormErrorHandler, FormErrorTranslatorComponent } from '@cue/admin-shared';
import { TabStripModule } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-localization-text-area',
  templateUrl: './localization-text-area.component.html',
  styleUrl: './localization-text-area.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, FormErrorTranslatorComponent, TabStripModule],
  standalone: true,
})
export class LocalizationTextAreaComponent extends FormErrorHandler implements OnInit {
  @Input({ required: true }) controlName: string;
  @Input({ required: true }) label: string;
  @Input() isRequired = true;
  @Input({ required: true }) userTranslations: { id: string; code: string }[] = [];
  @Input() placeholder: string = undefined;
  @Input() hideLabel = false;
  public parentForm: FormGroup;

  constructor(public controlContainer: ControlContainer) {
    super(controlContainer);
  }

  ngOnInit() {
    this.parentForm = this.controlContainer.control as FormGroup;
  }

  public imgError(event: any) {
    event.target.src = 'assets/img/placeholder.jpg';
  }

  public hasError(controlName: string): boolean {
    return this.parentForm.get(controlName).invalid;
  }
}
