@if (userTranslations.length) {
    <div class="form-group">
        <kendo-tabstrip class="tabs-w-padding">
            <kendo-tabstrip-tab [selected]="true">
                <ng-template kendoTabTitle>
                    {{ 'general.default' | translate }}
                </ng-template>
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="default"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            @for (language of userTranslations; track language.id) {
                <kendo-tabstrip-tab>
                    <ng-template kendoTabTitle>
                        <img
                            [src]="'assets/img/flags/' + language.code + '.svg'"
                            alt="language-flag"
                            class="flag"
                            width="30"
                            (error)="imgError($event)"
                        />
                        {{ language.code }}
                    </ng-template>
                    <ng-template kendoTabContent>
                        <input
                            [placeholder]="placeholder ?? ''"
                            type="text"
                            class="k-textbox"
                            [value]="getInitValue(language.code)"
                            [disabled]="isDisabled"
                            (input)="valueChange.emit({ value: $any($event.target).value, languageCode: language.code })"
                        />
                    </ng-template>
                </kendo-tabstrip-tab>
            }
        </kendo-tabstrip>
    </div>
} @else {
    <ng-container *ngTemplateOutlet="default"></ng-container>
}
<ng-template #default>
    <input
        [placeholder]="placeholder ?? ''"
        type="text"
        class="k-textbox"
        [value]="getInitValue('default')"
        [disabled]="isDisabled"
        (input)="valueChange.emit({ value: $any($event.target).value, languageCode: 'default' })"
    />
</ng-template>
