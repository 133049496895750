import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from '@angular/core';
import { FileLibraryService } from '@cue/admin-core';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import QRCode from 'qrcode';
import { combineLatest, defer, map, Observable, Observer } from 'rxjs';
import { Canvas, createCanvas } from 'canvas';

interface ImageToPrint {
  id: string;
  base64: string;
  height?: number;
  width?: number;
  element?: HTMLImageElement;
  url?: string;
  baseUrl?: string;
}

@Component({
  selector: 'app-qr-code-preview',
  templateUrl: './qr-code-preview.component.html',
  styleUrls: ['./qr-code-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzPipesModule, NgIf],
  standalone: true,
})
export class QrCodePreviewComponent {
  canvasToSvg$ = defer(() => import('canvas-to-svg').then((x) => x));

  @Input() qrCodeData;

  public baseUrl: string;
  public settings;
  @Input() set _printData(data) {
    if (data) {
      this.baseUrl = data.assistUrl.data.assistUrl + '/qr';
      this.settings = data.settings.data;
      this.getQrCodeBase64();
    }
  }

  public imageBase64: string;
  public svg: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fileLibraryService: FileLibraryService,
  ) {}

  get jpgChecked() {
    return this.settings.jpg;
  }

  get svgChecked() {
    return this.settings.svg;
  }

  get pngChecked() {
    return this.settings.png;
  }

  getQrCodeBase64() {
    let qrCodeBase64: string;

    let url = this.baseUrl + '/' + this.qrCodeData.id;
    if (this.settings.codeContent === 2) {
      url = 'qr/' + this.qrCodeData.id;
    }

    if (this.jpgChecked || this.pngChecked) {
      const qrCodeOpts = {
        type: this.jpgChecked ? 'image/jpeg' : 'image/png',
        width: 600,
        margin: 1,
        color: this.settings.transparentBackground ? { light: '#0000' } : null,
      };

      QRCode.toDataURL(url, qrCodeOpts, (err, base64) => {
        qrCodeBase64 = base64;
      });

      const imageIdList = [];
      if (this.qrCodeData.categoryImageId) imageIdList.push(this.qrCodeData.categoryImageId);
      if (this.qrCodeData.newsImageId) imageIdList.push(this.qrCodeData.newsImageId);
      let imagesToPrint: ImageToPrint[] = [{ id: 'qrCode', base64: qrCodeBase64 }];
      if (imageIdList.length) {
        this.fileLibraryService
          .imagesForPrint(imageIdList)
          .pipe(map((result) => (result.success ? result.data : [])))
          .subscribe((images) => {
            imagesToPrint = imagesToPrint.concat(images);
            this.getFinalCanvasJpgPng(qrCodeOpts, imagesToPrint);
          });
      } else {
        this.getFinalCanvasJpgPng(qrCodeOpts, imagesToPrint);
      }
    } else if (this.svgChecked) {
      QRCode.toString(
        url,
        {
          type: 'svg',
          color: {
            light: '#0000',
          },
          width: 150,
        },
        (err, svgString) => {
          if (err) throw err;
          qrCodeBase64 = 'data:image/svg+xml;base64,' + window.btoa(svgString);
        },
      );

      const imageIdList = [];
      if (this.qrCodeData.categoryImageId) imageIdList.push(this.qrCodeData.categoryImageId);
      if (this.qrCodeData.newsImageId) imageIdList.push(this.qrCodeData.newsImageId);
      let imagesToPrint: ImageToPrint[] = [{ id: 'qrCode', base64: qrCodeBase64 }];
      if (imageIdList.length) {
        this.fileLibraryService
          .imagesForPrint(imageIdList)
          .pipe(map((result) => (result.success ? result.data : [])))
          .subscribe((images) => {
            imagesToPrint = imagesToPrint.concat(images);
            this.getFinalCanvasSvg(imagesToPrint);
          });
      } else {
        this.getFinalCanvasSvg(imagesToPrint);
      }
    }
  }

  getFinalCanvasJpgPng(qrCodeOpts, imagesToPrint: ImageToPrint[]) {
    this.loadImages(imagesToPrint).subscribe((images) => {
      const finalCanvas = this.getCanvas(qrCodeOpts, images);

      this.imageBase64 = this.jpgChecked ? finalCanvas.toDataURL('image/jpeg') : finalCanvas.toDataURL('image/png');
      this.changeDetectorRef.detectChanges();
    });
  }

  getFinalCanvasSvg(imagesToPrint: ImageToPrint[]) {
    combineLatest([this.canvasToSvg$, this.loadImages(imagesToPrint)]).subscribe(([canvasToSvg, images]) => {
      if (images.length) {
        const finalSvg = this.getSvgString(canvasToSvg, 600, images);
        this.svg = finalSvg;

        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getCanvas(qrCodeOpts: any, imagesToPrint: ImageToPrint[]): Canvas {
    // nastaveni: font size a news image
    const fontSize = 80;
    const maxImageHeight = 800;

    // default canvas data
    let canvasWidth = 800;
    let canvasHeight = 800;
    const canvas = createCanvas(canvasWidth, canvasHeight);
    const ctx = canvas.getContext('2d');
    ctx.font = 'bold ' + fontSize + 'px Arial';

    // default QR code data
    let qrCodeTop = 100;
    let qrCodeLeft = 100;

    // default position
    let position = 0;

    // default code, name data, custom text
    let codeTop = 0;
    let nameTop = 0;
    let customTextTop = 0;

    // default resource
    let resourceNameTop = 0;

    // default news
    let newsTitleTop = 0;
    let newsImageTop = 0;
    let newsImageLeft = 0;
    let newsImageHeight = 800;
    let newsImageWidth = 800;

    // default common
    let categoryNameTop = 0;
    let categoryIconTop = 0;
    let categoryIconLeft = 0;
    const categoryIconHeight = 160;
    const categoryIconWidth = 160;

    // zjistit typ QR kodu
    let positionData = [];
    let customText = '';
    if (this.qrCodeData.resourceId) {
      positionData = this.settings.textPositionResource;
      customText = this.settings.resourceText;
    } else if (this.qrCodeData.newsId) {
      positionData = this.settings.textPositionNews;
      customText = this.settings.newsText;
    } else {
      positionData = this.settings.textPositionCommon;
      customText = this.settings.commonText;
    }

    positionData.forEach((positionItem) => {
      switch (positionItem.name) {
        case 'image': {
          qrCodeTop += position;
          position = qrCodeTop + qrCodeOpts.width;
          break;
        }
        case 'newsPicture': {
          const newsImage = imagesToPrint.find((x) => x.id === this.qrCodeData.newsImageId);
          if (newsImage) {
            newsImageHeight = newsImage.height;
            if (newsImageHeight > maxImageHeight) {
              const t = newsImageHeight / maxImageHeight;
              newsImageWidth = newsImage.width / t;
              newsImageHeight = maxImageHeight;
            }
            newsImageTop += position + 80;
            position += newsImageHeight + 80;
            canvasHeight += newsImageHeight + 80;
            canvasWidth = this.getCanvasWidth(newsImageWidth, canvasWidth);
          }
          break;
        }
        case 'categoryIcon': {
          const categoryIconImage = imagesToPrint.find((x) => x.id === this.qrCodeData.categoryImageId);
          if (categoryIconImage) {
            categoryIconTop += position + 80;
            position += categoryIconHeight + 80;
            canvasHeight += categoryIconHeight + 80;
            canvasWidth = this.getCanvasWidth(categoryIconWidth, canvasWidth);
          }
          break;
        }
        case 'qrCodeCode': {
          position += 120;
          codeTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(this.qrCodeData.code).width, canvasWidth);
          break;
        }
        case 'qrCodeName': {
          position += 120;
          nameTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(this.qrCodeData.name).width, canvasWidth);
          break;
        }
        case 'resourceDisplayName': {
          position += 120;
          resourceNameTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(this.qrCodeData.resourceDisplayName).width, canvasWidth);
          break;
        }
        case 'newsTitle': {
          position += 120;
          newsTitleTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(this.qrCodeData.newsTitle).width, canvasWidth);
          break;
        }
        case 'categoryName': {
          position += 120;
          categoryNameTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(this.qrCodeData.category).width, canvasWidth);
          break;
        }
        case 'customText': {
          position += 120;
          customTextTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(customText).width, canvasWidth);
          break;
        }
      }
    });
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // pozadi
    ctx.fillStyle = this.settings.transparentBackground ? 'rgba(255,255,255,0)' : 'white';
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    // nastaveni pisma
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'black';
    ctx.font = 'bold ' + fontSize + 'px Arial';

    positionData.forEach((positionItem) => {
      switch (positionItem.name) {
        case 'image': {
          const qrCodeImage = imagesToPrint.find((x) => x.id === 'qrCode');
          qrCodeLeft = (canvasWidth - qrCodeOpts.width) / 2;
          ctx.drawImage(qrCodeImage.element as any, qrCodeLeft, qrCodeTop, qrCodeOpts.width, qrCodeOpts.width);
          break;
        }
        case 'newsPicture': {
          const newsImage = imagesToPrint.find((x) => x.id === this.qrCodeData.newsImageId);
          if (newsImage) {
            newsImageLeft = (canvasWidth - newsImageWidth) / 2;
            ctx.drawImage(newsImage.element as any, newsImageLeft, newsImageTop, newsImageWidth, newsImageHeight);
          }
          break;
        }
        case 'categoryIcon': {
          const categoryIconImage = imagesToPrint.find((x) => x.id === this.qrCodeData.categoryImageId);
          if (categoryIconImage) {
            categoryIconLeft = (canvasWidth - categoryIconWidth) / 2;
            ctx.drawImage(categoryIconImage.element as any, categoryIconLeft, categoryIconTop, categoryIconWidth, categoryIconHeight);
          }
          break;
        }
        case 'qrCodeCode': {
          ctx.fillText(this.qrCodeData.code, canvasWidth / 2, codeTop);
          break;
        }
        case 'qrCodeName': {
          ctx.fillText(this.qrCodeData.name, canvasWidth / 2, nameTop);
          break;
        }
        case 'resourceDisplayName': {
          ctx.fillText(this.qrCodeData.resourceDisplayName, canvasWidth / 2, resourceNameTop);
          break;
        }
        case 'newsTitle': {
          ctx.fillText(this.qrCodeData.newsTitle, canvasWidth / 2, newsTitleTop);
          break;
        }
        case 'categoryName': {
          if (this.qrCodeData.category) {
            ctx.fillText(this.qrCodeData.category, canvasWidth / 2, categoryNameTop);
          }
          break;
        }
        case 'customText': {
          if (customText) {
            ctx.fillText(customText, canvasWidth / 2, customTextTop);
          }
          break;
        }
      }
    });

    return canvas;
  }

  getCanvasWidth(textLength: number, canvasWidth: number): number {
    if (textLength > canvasWidth - 20) {
      canvasWidth = Math.floor(textLength + 20);
    }
    return canvasWidth;
  }

  loadImages(imageData: ImageToPrint[]): Observable<ImageToPrint[]> {
    return Observable.create((observer: Observer<any>) => {
      let i = 0;
      const images = [];

      imageData.forEach((sourceImageData) => {
        const image = new Image();
        if (sourceImageData.id === 'qrCode') {
          image.src = sourceImageData.base64;
        } else {
          image.src = sourceImageData.url;
          image.crossOrigin = sourceImageData.baseUrl;
        }
        image.onload = () => {
          ++i;
          images.push({ ...sourceImageData, element: image });
          if (i === imageData.length) {
            observer.next(images);
            observer.complete();
          }
        };
      });
    });
  }

  getSvgString(canvasToSvg, qrCodeWidth: number, imagesToPrint: ImageToPrint[]): string {
    // nastaveni: font size a news image
    const fontSize = 80;
    const maxImageHeight = 800;

    // default canvas data
    let canvasWidth = 800;
    let canvasHeight = 800;
    const canvas = createCanvas(canvasWidth, canvasHeight);
    const ctx = canvas.getContext('2d');
    ctx.font = 'bold ' + fontSize + 'px Arial';

    // default QR code data
    let qrCodeTop = 100;
    let qrCodeLeft = 100;

    // default position
    let position = 0;

    // default code, name data, custom text
    let codeTop = 0;
    let nameTop = 0;
    let customTextTop = 0;

    // default resource
    let resourceNameTop = 0;

    // default news
    let newsTitleTop = 0;
    let newsImageTop = 0;
    let newsImageLeft = 0;
    let newsImageHeight = 800;
    let newsImageWidth = 800;

    // default common
    let categoryNameTop = 0;
    let categoryIconTop = 0;
    let categoryIconLeft = 0;
    const categoryIconHeight = 160;
    const categoryIconWidth = 160;

    // zjistit typ QR kodu
    let positionData = [];
    let customText = '';
    if (this.qrCodeData.resourceId) {
      positionData = this.settings.textPositionResource;
      customText = this.settings.resourceText;
    } else if (this.qrCodeData.newsId) {
      positionData = this.settings.textPositionNews;
      customText = this.settings.newsText;
    } else {
      positionData = this.settings.textPositionCommon;
      customText = this.settings.commonText;
    }

    positionData.forEach((positionItem) => {
      switch (positionItem.name) {
        case 'image': {
          qrCodeTop += position;
          position = qrCodeTop + qrCodeWidth;
          break;
        }
        case 'newsPicture': {
          const newsImage = imagesToPrint.find((x) => x.id === this.qrCodeData.newsImageId);
          if (newsImage) {
            newsImageHeight = newsImage.height;
            if (newsImageHeight > maxImageHeight) {
              const t = newsImageHeight / maxImageHeight;
              newsImageWidth = newsImage.width / t;
              newsImageHeight = maxImageHeight;
            }
            newsImageTop += position + 80;
            position += newsImageHeight + 80;
            canvasHeight += newsImageHeight + 80;
            canvasWidth = this.getCanvasWidth(newsImageWidth, canvasWidth);
          }
          break;
        }
        case 'categoryIcon': {
          const categoryIconImage = imagesToPrint.find((x) => x.id === this.qrCodeData.categoryImageId);
          if (categoryIconImage) {
            categoryIconTop += position + 80;
            position += categoryIconHeight + 80;
            canvasHeight += categoryIconHeight + 80;
            canvasWidth = this.getCanvasWidth(categoryIconWidth, canvasWidth);
          }
          break;
        }
        case 'qrCodeCode': {
          position += 120;
          codeTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(this.qrCodeData.code).width, canvasWidth);
          break;
        }
        case 'qrCodeName': {
          position += 120;
          nameTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(this.qrCodeData.name).width, canvasWidth);
          break;
        }
        case 'resourceDisplayName': {
          position += 120;
          resourceNameTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(this.qrCodeData.resourceDisplayName).width, canvasWidth);
          break;
        }
        case 'newsTitle': {
          position += 120;
          newsTitleTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(this.qrCodeData.newsTitle).width, canvasWidth);
          break;
        }
        case 'categoryName': {
          position += 120;
          categoryNameTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(this.qrCodeData.category).width, canvasWidth);
          break;
        }
        case 'customText': {
          position += 120;
          customTextTop = position;
          canvasHeight += 120;
          canvasWidth = this.getCanvasWidth(ctx.measureText(customText).width, canvasWidth);
          break;
        }
      }
    });
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const svgCtx = new canvasToSvg.default(canvasWidth, canvasHeight);
    svgCtx.fillStyle = this.settings.transparentBackground ? 'rgba(255,255,255,0)' : 'white';
    svgCtx.fillRect(0, 0, canvasWidth, canvasHeight);

    // nastaveni pisma
    svgCtx.textAlign = 'center';
    svgCtx.textBaseline = 'middle';
    svgCtx.fillStyle = 'black';
    svgCtx.font = 'bold ' + fontSize + 'px Arial';

    positionData.forEach((positionItem) => {
      switch (positionItem.name) {
        case 'image': {
          const image = imagesToPrint.find((x) => x.id === 'qrCode');
          if (image) {
            qrCodeLeft = (canvasWidth - qrCodeWidth) / 2;
            svgCtx.drawImage(image.element, qrCodeLeft, qrCodeTop, qrCodeWidth, qrCodeWidth);
          }
          break;
        }
        case 'newsPicture': {
          const image = imagesToPrint.find((x) => x.id === this.qrCodeData.newsImageId);
          if (image) {
            newsImageLeft = (canvasWidth - newsImageWidth) / 2;
            svgCtx.drawImage(image.element, newsImageLeft, newsImageTop, newsImageWidth, newsImageHeight);
          }
          break;
        }
        case 'categoryIcon': {
          const image = imagesToPrint.find((x) => x.id === this.qrCodeData.categoryImageId);
          if (image) {
            categoryIconLeft = (canvasWidth - categoryIconWidth) / 2;
            svgCtx.drawImage(image.element, categoryIconLeft, categoryIconTop, categoryIconWidth, categoryIconHeight);
          }
          break;
        }
        case 'qrCodeCode': {
          svgCtx.fillText(this.qrCodeData.code, canvasWidth / 2, codeTop);
          break;
        }
        case 'qrCodeName': {
          svgCtx.fillText(this.qrCodeData.name, canvasWidth / 2, nameTop);
          break;
        }
        case 'resourceDisplayName': {
          svgCtx.fillText(this.qrCodeData.resourceDisplayName, canvasWidth / 2, resourceNameTop);
          break;
        }
        case 'newsTitle': {
          svgCtx.fillText(this.qrCodeData.newsTitle, canvasWidth / 2, newsTitleTop);
          break;
        }
        case 'categoryName': {
          if (this.qrCodeData.category) {
            svgCtx.fillText(this.qrCodeData.category, canvasWidth / 2, categoryNameTop);
          }
          break;
        }
        case 'customText': {
          if (customText) {
            svgCtx.fillText(customText, canvasWidth / 2, customTextTop);
          }
          break;
        }
      }
    });

    return svgCtx.getSerializedSvg();
  }

  public imgError(event: any) {
    event.target.src = 'assets/img/placeholder.jpg';
  }
}
