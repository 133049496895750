<ng-container [formGroup]="parentForm">
    @if (userTranslations.length) {
        <div class="form-group">
            @if (!hideLabel) {
                <label class="k-form-field">
                    <span>
                        {{ label | translate }}
                        @if (isRequired) {
                            <span class="error">*</span>
                        }
                    </span>
                    <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
                </label>
            }
            <kendo-tabstrip class="tabs-w-padding">
                <kendo-tabstrip-tab [selected]="true">
                    <ng-template kendoTabTitle>
                        {{ 'general.default' | translate }}
                        @if (hasError(controlName)) {
                            <div class="error tab-error">!</div>
                        }
                    </ng-template>
                    <ng-template kendoTabContent>
                        <textarea [id]="controlName" [formControlName]="controlName" type="textarea" class="k-textarea"></textarea>
                        <div class="error" *ngFor="let err of getErrors(controlName) | keyvalue">
                            <app-form-error-translator [key]="err.key" [value]="err.value"></app-form-error-translator>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>
                @for (language of userTranslations; track language.id) {
                    <kendo-tabstrip-tab>
                        <ng-template kendoTabTitle>
                            <img
                                [src]="'assets/img/flags/' + language.code + '.svg'"
                                alt="language-flag"
                                class="flag"
                                width="30"
                                (error)="imgError($event)"
                            />
                            {{ language.code }}
                        </ng-template>
                        <ng-template kendoTabContent>
                            <textarea
                                [id]="controlName + '-' + language.code"
                                [formControlName]="controlName + '-' + language.code"
                                type="textarea"
                                class="k-textarea"
                            ></textarea>
                            <div class="error" *ngFor="let err of getErrors(controlName + '-' + language.code) | keyvalue">
                                <app-form-error-translator [key]="err.key" [value]="err.value"></app-form-error-translator>
                            </div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                }
            </kendo-tabstrip>
        </div>
    } @else {
        <div class="form-group k-form-field">
            @if (!hideLabel) {
                <label [for]="controlName">
                    {{ label | translate }}
                    @if (isRequired) {
                        <span class="error">*</span>
                    }
                </label>
                <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
            }
            <textarea
                [id]="controlName"
                [formControlName]="controlName"
                type="textarea"
                class="k-textarea"
                [placeholder]="placeholder ? placeholder : ''"
            ></textarea>
            <div class="error" *ngFor="let err of getErrors(controlName) | keyvalue">
                <app-form-error-translator [key]="err.key" [value]="err.value"></app-form-error-translator>
            </div>
        </div>
    }
    <ng-template #contentTemplate>
        <ng-content></ng-content>
    </ng-template>
</ng-container>
