@if (userTranslations.length) {
    <div class="form-group">
        <kendo-tabstrip class="tabs-w-padding">
            <kendo-tabstrip-tab [selected]="true">
                <ng-template kendoTabTitle>
                    {{ 'general.default' | translate }}
                </ng-template>
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="default"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
            @for (language of userTranslations; track language.id) {
                <kendo-tabstrip-tab>
                    <ng-template kendoTabTitle>
                        <img
                            [src]="'assets/img/flags/' + language.code + '.svg'"
                            alt="language-flag"
                            class="flag"
                            width="30"
                            (error)="imgError($event)"
                        />
                        {{ language.code }}
                    </ng-template>
                    <ng-template kendoTabContent>
                        <quill-editor
                            [modules]="quillModules"
                            [placeholder]="placeholder ?? ''"
                            [readOnly]="isDisabled"
                            [ngModel]="getInitValue(language.code)"
                            (onContentChanged)="valueChange.emit({ value: $event, languageCode: language.code })"
                        ></quill-editor>
                    </ng-template>
                </kendo-tabstrip-tab>
            }
        </kendo-tabstrip>
    </div>
} @else {
    <ng-container *ngTemplateOutlet="default"></ng-container>
}
<ng-template #default>
    <quill-editor
        [modules]="quillModules"
        [placeholder]="placeholder ?? ''"
        [readOnly]="isDisabled"
        [ngModel]="getInitValue('default')"
        (onContentChanged)="valueChange.emit({ value: $event, languageCode: 'default' })"
    ></quill-editor>
</ng-template>
